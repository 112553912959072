import React from "react"
import { Link, graphql } from "gatsby"
import { Dialog } from "@blueprintjs/core"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import TheaterVideo from "@components/Theater/TheaterVideo"
import CloseIcon from "../components/CloseIcon"
import Layout from "../components/layout"
import { Heading } from "../components/Hero"
import { Section, Columns } from "../components/Containers"
import { CallToAction } from "../components/CTA"
import { Image, Text } from "../components/Core"
import AnesthesiaModal from "../components/Modals/AnesthesiaModal"

class AnesthesiaOptionsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogIsOpen: false,
      componentShownInDialog: {}
    }
    this.toggleDialog = this.toggleDialog.bind(this)
  }

  componentDidUpdate(prevState) {
    if (
      this.state.dialogIsOpen !== prevState.dialogIsOpen &&
      this.state.dialogIsOpen
    ) {
      document.documentElement.style.overflowY = "hidden"
    } else {
      document.documentElement.style.overflowY = "scroll"
    }
  }

  toggleDialog(component) {
    this.setState(prevState => ({
      dialogIsOpen: !prevState.dialogIsOpen,
      componentShownInDialog: component
    }))
  }

  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]
    return (
      <SharedStateProvider>
        <Layout pageTitle="an-options">
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            pathname={this.props.location.pathname}
          />

          <Dialog
            canOutsideClickClose
            enforceFocus
            autoFocus
            canEscapeKeyClose
            isOpen={this.state.dialogIsOpen}
            usePortal
            onClose={this.toggleDialog}
            className="anesthesia-dialog">
            {this.state.componentShownInDialog}
          </Dialog>

          {!post.imageId ? (
            <Heading heading={post.heading} blurb={post.topBlurbs} />
          ) : (
            <div className="an-options__hero nav-spacer">
              <Image
                useAR
                publicId={post.imageId}
                className="procedure-hero-img"
              />

              <Section smallSpacing>
                <Columns sideColumnsSize={3}>
                  <div className="column">
                    <Text className="mb-0" as="h1" text={post.heading} />
                    <Text className="mt-0" as="h2" text={post.subHeading} />
                  </div>
                  {post.youtube && (
                    <>
                      <div className="column is-1"></div>
                      <div className="column is-narrow">
                        <TheaterVideo
                          language="en"
                          videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                          controls
                          playing
                          hasWatchVideo
                          buttonClass="contained mt-0 mx-auto--mobile"
                        />
                      </div>{" "}
                    </>
                  )}
                </Columns>
              </Section>
            </div>
          )}

          <Section zeroTop className="anesthesia-body-section">
            <Columns sideColumnsSize={5}>
              <div className="column">
                <Text useStringReplace text={post.body} />
              </div>
            </Columns>
          </Section>
          {/* <Section>
            <Columns className="has-text-centered">
              <div className="column">
                <AnesthesiaModal clearBlurbLink />
              </div>
            </Columns>
          </Section> */}

          <Section>
            <Columns sideColumnsSize={5}>
              <div className="column">
                <Text useStringReplace text={post.bodyTwo} />
              </div>
            </Columns>
          </Section>

          <CallToAction
            colorBack
            heading={post.getStarted.heading}
            blurb={post.getStarted.blurb}
            buttons={post.getStarted.buttons}
          />
        </Layout>
      </SharedStateProvider>
    )
  }
}

export const pageQuery = graphql`
  query anesthesiaOptionsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        heading
        subHeading
        anesthesiaTypes {
          buttons {
            buttonText
            heading
            blurb
            youtube
            href
          }
          blurb
          noInlinkBlurb
          heading
        }
        youtube
        imageId
        body
        bodyTwo
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
          buttons {
            button {
              appearance
              destination
              buttonText
              href
            }
          }
        }
      }
    }
  }
`

export default AnesthesiaOptionsPage
